import { createHttpLink } from '@apollo/client/core'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'

export function createErrorLink({ $sentry, env }) {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      const graphQLErrorsMessages = graphQLErrors.map(
        ({ message, locations, path }) => {
          return `[Apollo GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        }
      )

      console.log('GraphQLErrors: ', graphQLErrorsMessages)

      if (env.sentryDsn && $sentry) {
        $sentry.captureException(new Error(graphQLErrorsMessages))
      }
    }

    if (networkError) {
      const networkErrorMessage = `[Apollo Network error)]: ${networkError} - Cause: ${networkError.cause}`

      console.log('Network Error: ', networkErrorMessage)

      if (env.sentryDsn && $sentry) {
        $sentry.captureException(new Error(networkErrorMessage))
      }
    }
  })
}

export function createRetryLink({ maxRetries }) {
  return new RetryLink({
    delay: {
      initial: 200,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: maxRetries,
      retryIf: (error, operation) => {
        return !!error
      }
    }
  })
}

export function createUnauthenticatedHttpLink(env) {
  return createHttpLink({
    uri: env.apiEndpoint
  })
}

export function createAuthenticatedHttpLink(env) {
  return createHttpLink({
    uri: env.shopifyEndpoint,
    headers: {
      'X-Shopify-Storefront-Access-Token': env.shopifyToken
    }
  })
}
